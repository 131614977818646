<template>
  <div>
    <FilterCorsi @getCorsiList="searchCorsiList" @resetFilters="resetFilters" />
    <TableCorsi @getCorsiList="getCorsiList" @resetFilters="resetFilters" />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/composables/trimInput";

import FilterCorsi from "@/components/components-fit/SNM/gestione-corsi/FiltriCorsi.vue";
import TableCorsi from "@/components/components-fit/SNM/gestione-corsi/TableCorsi.vue";

export default defineComponent({
  name: "corsi",
  components: { FilterCorsi, TableCorsi },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Lista Corsi", []);
    });

    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const stagione = computed(() => store.getters.stagioneSelected);
    const rowsToSkip = computed(() => store.getters.rowsToSkipCorsi);
    const fetchRows = computed(() => store.getters.fetchRowsCorsi);
    const sortColumn = computed(() => store.getters.sortColumnCorsi);
    const sortOrder = computed(() => store.getters.sortOrderCorsi);

    const id_tipo_corso = computed(() => store.getters.tipologiaCorsi);
    const id_sottotipo_corso = computed(() => store.getters.specializzCorsi);
    const search = computed(() => store.getters.denominazioneCorsi);

    const getCorsiList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          stagione: stagione.value,
          id_tipo_corso: id_tipo_corso.value,
          id_sottotipo_corso: id_sottotipo_corso.value,
          search: trimInput(search.value),
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.CORSI_LIST,
        itemName: "corsi_list",
      });
    };

    const resetFilters = () => {
      store.commit("spliceArrayFromName", "snm_corsi_sottotipo");
      store.commit("resetFiltersCorsi");
      getCorsiList();
    };
    const loaded = computed(() =>
      store.getters.getStateFromName("loadedcorsi_list")
    );
    const searchCorsiList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipCorsi");
      getCorsiList();
    };

    getCorsiList();

    return {
      getCorsiList,
      resetFilters,
      searchCorsiList,
    };
  },
});
</script>
