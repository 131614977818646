<template>
  <div>
    <div class="card shadow">
      <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getCorsiList')">
        <div class="row">
          <div class="col-sm-3 filter">
            <SelectInput
              :options="snm_corsi_tipo"
              name="snm_corsi_tipo"
              placeholder="Tipologia corsi"
              :value="tipologiaCorsi"
              @changeSelect="
                setTipologiaCorsi($event);
                getSpecializzCorsi(tipologiaCorsi);
                setSpecializzCorsi(null);
                resetCorsiSpec();
              "
            />
          </div>
          <div class="col-sm-3 filter">
            <SelectInput
              :options="snm_corsi_sottotipo"
              name="snm_corsi_sottotipo"
              placeholder="Specializzazione corsi"
              :value="specializzCorsi"
              @changeSelect="setSpecializzCorsi($event)"
              :disabled="!tipologiaCorsi"
            />
          </div>
          <div class="col-sm-6 filter">
            <input
              type="text"
              class="form-control"
              placeholder="Denominazione"
              aria-label=""
              :value="denominazioneCorsi"
              @input="setDenominazioneCorsi"
            />
          </div>
        </div>
        <div class="text-center mt-5">
          <button
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600"
            @click="$emit('getCorsiList')"
            :disabled="!loaded"
          >
            Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
          </button>
          <span
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click="
              $emit('resetFilters');
              resetCorsiSpec();
              specializzCorsi = null;
            "
          >
            <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "filtri-corsi",
  emits: ["resetFilters", "getCorsiList"],
  props: {
    campiLocked: {
      type: String,
      required: true,
    },
  },
  components: {
    SelectInput,
  },
  setup(props, { emit }) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const snm_corsi_tipo = computed(() =>
      store.getters.getStateFromName("snm_corsi_tipo")
    );

    const snm_corsi_sottotipo = computed(() =>
      store.getters.getStateFromName("snm_corsi_sottotipo")
    );

    const tipologiaCorsi = computed(() => store.getters.tipologiaCorsi);
    const specializzCorsi = computed(() => store.getters.specializzCorsi);
    const denominazioneCorsi = computed(() => store.getters.denominazioneCorsi);

    const keys = ref("sct");
    if (!snm_corsi_tipo.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const setDenominazioneCorsi = (e) => {
      const string = e.target.value;
      store.commit("setDenominazioneCorsi", string);
    };

    const setTipologiaCorsi = (event) => {
      store.commit("setTipologiaCorsi", event);
    };

    const setSpecializzCorsi = (event) => {
      store.commit("setSpecializzCorsi", event);
    };

    const getSpecializzCorsi = (id) => {
      store.dispatch("setStoreData", {
        keys: { id_Tipo_Corso: id },
        apiLink: globalApi.LOOKUP_SPECIALIZZAZIONE_CORSI,
      });
    };

    const resetCorsiSpec = () => {
      store.commit("spliceArrayFromName", "snm_corsi_sottotipo");
      specializzCorsi.value = null;
    };

    const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione, () => {
      emit("getCorsiList");
    });

    return {
      resetCorsiSpec,
      stagione: computed(() => store.getters.stagioneSelected),
      snm_corsi_tipo,
      getSpecializzCorsi,
      snm_corsi_sottotipo,
      setDenominazioneCorsi,
      denominazioneCorsi,
      setSpecializzCorsi,
      specializzCorsi,
      setTipologiaCorsi,
      tipologiaCorsi,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedcorsi_list")
      ),
    };
  },
});
</script>

<style></style>
